<script>
  import PublicParticipantsLayer from "./PublicParticipantsLayer.svelte";
  import Map from "./map/Map.svelte";
  import Participants from "./participants/Participants.svelte";
  import Marker from "./map/Marker.svelte";
  import { controls } from "./map/components";
  import mapboxgl from "mapbox-gl";
  import { MAPBOX_TOKEN } from "../config.js";

  const { GeolocateControl, NavigationControl, ScalingControl } = controls;

  export let mapOptions = {};
  let mapComponent;
</script>

<div class="aap-map">
  <Map
    bind:this={mapComponent}
    accessToken={MAPBOX_TOKEN}
    options={mapOptions}
    on:recentre
  >
    <NavigationControl />
    <GeolocateControl options={{ some: "control-option" }} />
    <ScalingControl />
    <slot />
  </Map>
</div>

<style>
  .aap-map {
    width: 100%;
    height: 100%;
  }
</style>
