<script>
  import { BOUNDS_OPTIONS } from "./../config.js";
  import {
    getParticipantNameAdmin,
    getPopupContent,
  } from "./../utils/participant.utils.js";
  import Marker from "./map/Marker.svelte";
  import Popup from "./map/Popup.svelte";
  import AapList from "./AapList.svelte";
  import bbox from "@turf/bbox";
  import { lineString } from "@turf/helpers";
  import AapMap from "./AapMap.svelte";
  import AapSearchInput from "./AapSearchInput.svelte";
  import { getContext } from "svelte";
  import VirtualList from "@sveltejs/svelte-virtual-list";
  import ParticipantItemAdmin from "./ParticipantItemAdmin.svelte";
  import queryString from "query-string";

  import { contextKey, mapbox } from "./map/mapbox.js";

  $: query = [];
  let typeFilter = ["baasje", "plusbaasje"];
  let start;
  let end;
  $: filterQuery = selectedValue
    ? selectedValue.map((item) => item.value)
    : undefined;
  export let showOnlyPublic = true;
  export let showUnmatched = true;
  export let showMatched = true;
  export let participants = [];

  let parsed = {};

  if (typeof window !== "undefined") {
    parsed = queryString.parse(window.location.search);
  }

  let postcode = parsed.postcode;

  let selectedParticipantId = parsed.contactId;

  let selectedValue = postcode ? [postcode] : undefined;

  let searchValue = "";

  const defaultMapOptions = {
    zoom: 15,
  };

  const setQuery = (query) => {
    selectedValue = options.filter((opt) => query.includes(opt.value));
  };

  const handleChangeQuery = (e) => {
    setQuery(e.detail ? e.detail.map((v) => v.value) : "");
  };

  $: getMapOptions = () => {
    if (selectedParticipantId) {
      const participant = participants
        .filter((p) => p.location)
        .find((p) => p.id === selectedParticipantId);
      if (participant && participant.location) {
        return {
          center: {
            lat: participant.location[1],
            lng: participant.location[0],
          },
          zoom: 15,
        };
      }
    }
    if (!participants) {
      return defaultMapOptions;
    } else {
      const boundsLocations = participants
        .filter((p) => p.location)
        .map((p) => p.location);

      if (boundsLocations.length < 2) {
        return {
          ...defaultMapOptions,
          center: boundsLocations[0],
        };
      } else {
        const bounds = bbox(lineString(boundsLocations));
        return {
          bounds: bounds && bounds.length > 1 ? bounds : nqull,
          fitBoundsOptions: BOUNDS_OPTIONS,
        };
      }
    }
  };

  $: filteredList = participants
    .filter((p) => {
      if (searchValue === "") {
        return true;
      }
      const searchQuery = searchValue.toLowerCase();
      const huisdier = p.huisdier ? p.huisdier.toLowerCase() : "";
      return (
        p.firstName.toLowerCase().includes(searchQuery) ||
        huisdier.includes(searchQuery) ||
        p.zip.includes(searchQuery) ||
        p.lastName.toLowerCase().includes(searchQuery)
      );
    })
    .filter((p) =>
      !filterQuery ? true : filterQuery.indexOf(p.zip.toString()) !== -1
    )
    .filter((p) => typeFilter.includes(p.type))
    .filter((p) => {
      if (showUnmatched && showMatched) {
        return true;
      }

      if (showUnmatched) {
        return !p.matched;
      }

      if (showMatched) {
        return p.matched === true;
      }
    });

  let map;

  const handleClick = (e) => {
    selectedParticipantId = e.detail.id;
  };

  $: cityZips = participants
    .filter((p) => p.zip)
    .reduce((p, n) => {
      return { ...p, [n.zip]: n.city };
    }, {});

  $: options = Object.keys(cityZips).map((k) => ({
    value: k,
    label: cityZips[k] + " (" + k + ")",
  }));

  $: listItems = filteredList.map((p) => ({ ...p, key: p.id })) || [];

  $: selectedParticipant = !selectedParticipantId
    ? undefined
    : participants.find((p) => {
        return p.bitrixID.toString() === selectedParticipantId.toString();
      });
</script>

<div class="wrapper">
  <AapMap mapOptions={getMapOptions()}>
    {#each filteredList.filter((p) => p.location) as participant (participant.id)}
      <Marker
        id={participant.id}
        label={getPopupContent(participant)}
        lat={participant.location[1]}
        lon={participant.location[0]}
        type={participant.type}
      />
    {/each}
    {#if selectedParticipantId}
      <Popup
        label={getPopupContent(selectedParticipant)}
        lat={selectedParticipant.location[1]}
        lon={selectedParticipant.location[0]}
      />
    {/if}
  </AapMap>
  <div class="sidebar-search">
    <AapSearchInput
      bind:showOnlyPublic
      bind:showUnmatched
      bind:showMatched
      bind:selectedValue
      bind:searchValue
      {options}
      bind:typeFilter
    />
    <p class="list-meta text-right p-2">
      {start} - {end} van {listItems.length}
    </p>

    <div class="list">
      {#if listItems.length > 0}
        <VirtualList items={listItems} bind:start bind:end let:item>
          <ParticipantItemAdmin on:click={handleClick} participant={item} />
        </VirtualList>
      {/if}
    </div>
  </div>
</div>

<style>
  .wrapper {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: stretch;
    flex: 1;
  }

  .sidebar-search {
    /* position: absolute; */
    /* top: 0; */
    /* right: 0; */
    min-width: 400px;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .list {
    overflow: hidden;
    height: 100%;
  }
  .list-meta {
    font-size: 0.8rem;
  }
</style>
