export const MAPBOX_TOKEN =
  "pk.eyJ1IjoicmFtc2Vzei1hYXB2enciLCJhIjoiY2s3eXJkaTR0MDB3ZTNkbndlZ3pscmVraCJ9.eUpwa2wTuG6ZBCEf-fpCXg";

export const BUCKET_URL = 'http://storage.googleapis.com/aapvzw-search-engine.appspot.com/'

export const COLORS = {
  BAASJE: "#f1592a",
  PLUSBAASJE: "#97d5c9",
};

export const DEFAULT_CENTER = {
  lat: 51.3162495,
  lon: 4.4195445,
};

export const DEFAULT_ZOOM = 15;

export const zipMapping = {
  "2000": "2000",
  "2018": "2000",
  "2020": "2000",
  "2050": "2000",
  "2100": "2000",
  "2140": "2000",
  "2170": "2000",
  "2180": "2000",
  "2600": "2000",
  "2610": "2000",
  "2660": "2000",
  "2070": "2070",
  "2160": "2160",
  "2640": "2640",
  "2800": "2800",
  "2811": "2800",
  "2812": "2800",
  "2820": "2820",
  "2860": "2860",
  "2900": "2900",
  "2950": "2950",
  "3920": "3920"
}


export const BOUNDS_OPTIONS = { padding: 60 }