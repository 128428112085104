<script>
  import { onDestroy, getContext } from "svelte";
  import { COLORS } from "../../config.js";

  import { contextKey, mapbox } from "../map/mapbox";

  const { getMap } = getContext(contextKey);

  const map = getMap();

  export let id;
  export let lat;
  export let lon;
  export let label;
  export let type;

  const popup = new mapbox.Popup().setHTML(label);

  const options = {
    color: type === "baasje" ? COLORS.BAASJE : COLORS.PLUSBAASJE,
    className: "marker-" + id
  };

  const marker = new mapbox.Marker(options)
    .setLngLat([lon, lat])
    .setPopup(popup)

    .addTo(map);

  onDestroy(() => {
    marker.remove();
  });
</script>
