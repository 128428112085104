<script>
  import { Collection, User } from "sveltefire";
  import firebase from "firebase/app";
  import "firebase/auth";

  import AapSearch from "./AapSearch.svelte";

  let showOnlyPublic;
  const provider = new firebase.auth.GoogleAuthProvider();
</script>

<div>
  <User let:user let:auth>
    <div slot="signed-out">
      <div class="p-3">
        <button on:click={() => auth.signInWithPopup(provider)}>Sign in</button>
      </div>
    </div>
    <div>
      <Collection
        path={"participants"}
        traceId={"readParticipants"}
        query={(ref) => {
          let query = ref;
          if (showOnlyPublic) {
            query = ref.where("public", "==", "1");
          }
          query = ref.where("firstName", ">", "").orderBy("firstName");
          return query;
        }}
        log
        let:data={participants}
        let:ref={participantsRef}
        let:last={firstParticipant}
        let:first={lastParticipant}
        on:data
        on:ref
      >
        <AapSearch
          participants={participants.filter((p) => p.zip !== null)}
          bind:showOnlyPublic
        />

        <div class="p-3" slot="loading">Loading...</div>

        <div class="p-3" slot="fallback">
          Error while loading participants...
        </div>
      </Collection>
    </div>
  </User>
</div>
