<script>
  import { BUCKET_URL, COLORS } from "./../config.js";
  export let type;
  export let picture;

  const pictureURL = BUCKET_URL + picture;

  export let size = "sm";
</script>

<div class="avatar-wrap">
  <div class="avatar">
    {#if picture}
      <div
        class="avatar-pic {size}"
        style={`background-image: url(${BUCKET_URL + picture});`}
        alt="avatar"
      />
    {:else if type === "baasje"}
      <i
        class="material-icons circle"
        style="background-color: {COLORS.BAASJE};"
      >
        pets
      </i>
    {:else}
      <i
        class="material-icons circle"
        style="background-color: {COLORS.PLUSBAASJE};"
      >
        face
      </i>
    {/if}
  </div>
</div>

<style>
  .avatar {
    text-align: center;
  }
  .avatar i {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: black;
  }

  .avatar-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    float: left;
    margin-right: 16px;
  }
  .avatar-pic {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    line-height: 50px;
    background-size: cover;
    background-position: center center;
  }

  .avatar-pic.sm {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }

  .avatar-pic.lg {
    width: 90px;
    height: 90px;
  }
</style>
