<script>
  import loader from "@beyonk/async-script-loader";
  import { contextKey } from "./mapbox.js";
  import { DEFAULT_CENTER, DEFAULT_ZOOM } from "../../config";
  import { onMount, createEventDispatcher, setContext } from "svelte";
  import {} from "./mapbox.js";

  setContext(contextKey, {
    getMap: () => map,
    getMapbox: () => mapbox
  });

  const dispatch = createEventDispatcher();

  export let map = null;
  export let version = "v1.10.0";

  let container;
  let mapbox;

  export let accessToken;
  export let style = "mapbox://styles/mapbox/streets-v11";

  export let options = {
    center: DEFAULT_CENTER,
    zoom: DEFAULT_ZOOM
  };

  $: if (options !== undefined) {
    setOptions(options);
  }

  export const setOptions = options => {
    if (map) {
      map.easeTo({
        ...options
      });
    }
  };

  export function flyTo(destination) {
    map && map.flyTo(destination);
  }

  export function resize() {
    map && map.resize();
  }

  export function getMap() {
    return map;
  }

  function onAvailable() {
    mapbox = mapboxgl;
    mapboxgl.accessToken = accessToken;

    if (container) {
      const optionsWithDefaults = Object.assign(
        {
          container,
          style
        },
        options
      );
      const el = new mapbox.Map(optionsWithDefaults);
      el.on("dragend", () => dispatch("recentre", { center: el.getCenter() }));
      el.on("load", () => {
        map = el;
        dispatch("ready");
      });
    }
  }

  onMount(async () => {
    loader(
      [
        {
          type: "script",
          url: `//api.mapbox.com/mapbox-gl-js/${version}/mapbox-gl.js`
        },
        {
          type: "style",
          url: `//api.mapbox.com/mapbox-gl-js/${version}/mapbox-gl.css`
        }
      ],
      () => !!window.mapboxgl,
      onAvailable
    );
    return () => {
      map.remove();
    };
  });
</script>

<style>
  div {
    width: 100%;
    height: 100%;
  }
</style>

<div bind:this={container}>
  {#if map}
    <slot />
  {/if}
</div>
