<script>
  import { onDestroy, getContext } from "svelte";

  import { contextKey, mapbox } from "../map/mapbox";

  const { getMap } = getContext(contextKey);

  const map = getMap();

  // export let id;
  export let lat;
  export let lon;
  export let label;

  const popupOptions = { closeOnClick: false };

  const popup = new mapbox.Popup(popupOptions)
    .setHTML(label)
    .setLngLat([lon, lat + 0.0006])
    .addTo(map);

  onDestroy(() => {
    popup.remove();
  });
</script>
