<script>
  import Select from "svelte-select";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  let types = ["baasje", "plusbaasje"];

  export let typeFilter = [];

  export let options;
  export let showOnlyPublic = false;
  export let showUnmatched = true;
  export let showMatched = true;
  export let selectedValue = undefined;
  export let searchValue = "";
</script>

<div class="wrap">
  <Select
    bind:selectedValue
    isMulti={true}
    on:select
    on:clear
    placeholder="Selecteer postcode"
    items={options}
  />
  <form class="mt-2">
    <p class="mb-2">
      <label>
        <input type="checkbox" bind:checked={showOnlyPublic} />
        <span>Toon enkel degene die publiek</span>
      </label>
    </p>
    <div class="row">
      <div class="col-6">
        <p class="filters">
          <label>
            <input type="checkbox" bind:checked={showMatched} />
            <span>gematched</span>
          </label>
        </p>
        <p>
          <label>
            <input type="checkbox" bind:checked={showUnmatched} />
            <span>niet gematched</span>
          </label>
        </p>
      </div>
      <div class="col-6">
        {#each types as type}
          <p>
            <label>
              <input type="checkbox" bind:group={typeFilter} value={type} />
              <span>{type}</span>
            </label>
          </p>
        {/each}
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <input
          class="p-2"
          type="text"
          placeholder="Zoek op naam, huisdier, postcode..."
          bind:value={searchValue}
        />
      </div>
    </div>
  </form>
</div>

<style>
  .wrap {
    padding: 1rem 1rem;
    text-align: center;
    text-align: left;
  }
  label {
    font-size: 12px;
  }
</style>
