import { BUCKET_URL, COLORS } from "../config";
export const getParticipantName = (participant) => {
  return participant.type === "baasje"
    ? participant.huisdier ||
        "!" + participant.firstName + " " + participant.lastName + "!"
    : participant.firstName;
};
export const getParticipantNameAdmin = (participant) => {
  return participant.type === "baasje"
    ? participant.firstName +
        " " +
        participant.lastName +
        " (" +
        participant.huisdier +
        ")" || "!" + participant.firstName + " " + participant.lastName + "!"
    : participant.firstName + " " + participant.lastName;
};

export const getParticipantAddress = (participant) => {
  return `${participant.street || ""}  ${participant.number || ""}<br />${
    participant.zip || ""
  }  ${participant.city || ""}`;
};

export const getPopupContent = (participant) => {
  let html = `<div style="padding: 1rem;text-align:left;">`;
  html += `<h3 class="title mt-4" style="font-weight:bold">`;
  html += participant.firstName + " " + participant.lastName;
  html += `</h3>`;
  if (participant.huisdier) {
    html += `<h5>${participant.huisdier}</h5>`;
  }
  html += `<p class="mb-1" style="font-style: italic;color: ${
    participant.type === "baasje" ? COLORS.BAASJE : COLORS.PLUSBAASJE
  };"><i>${participant.type}</i></p>`;
  if (participant.pictureUrl) {
    html += `<img src="${
      BUCKET_URL + participant.pictureUrl
    }" alt="avatar" height="40" />`;
  }
  html += `<p class="mt-2">`;
  html += `<i>`;
  html += getParticipantAddress(participant);

  html += `</i>`;
  html += `</p>`;
  html += `<p class="mt-2">${participant.description || ""}</p>`;
  html += `<a
    class="my-2"
    href="https://aapvzw.bitrix24.com/crm/contact/details/${participant.id}/"
    target="_blank"
    rel="noreferrer"
    >
    <span class="badge badge-info">bitrix</span>
  </a>`;
  html += `</div>`;
  return html;
};
