<script>
  import { getContext } from "svelte";
  import { contextKey, mapbox } from "../mapbox.js";

  const { getMap } = getContext(contextKey);
  const map = getMap();

  export let position = "bottom-right";
  export let options = {};

  const optionsWithDefaults = Object.assign(
    {
      maxWidth: 80,
      unit: "metric"
    },
    options
  );

  const scale = new mapbox.ScaleControl(optionsWithDefaults);
  map.addControl(scale, position);
</script>
