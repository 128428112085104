<script>
  import BitrixView from "./components/BitrixView.svelte";
  import { FirebaseApp, User, Doc, Collection } from "sveltefire";
  import AapSearch from "./components/AapSearch.svelte";
  import PublicView from "./components/PublicView.svelte";
  import AapSearchWebsite from "./components/AapSearchWebsite.svelte";
  import firebase from "firebase/app";
  import "firebase/firestore";
  import "firebase/auth";
  import "firebase/performance";
  import "firebase/analytics";
  import "../node_modules/materialize-css/dist/css/materialize.css";
  import queryString from "query-string";
  import geofirex from "geofirex";

  let firebaseConfig = {
    apiKey: "AIzaSyBkXwxmx2-qyGGj_WH-CpwLb7ZYyVZEVfo",
    authDomain: "aapvzw-search-engine.firebaseapp.com",
    databaseURL: "https://aapvzw-search-engine.firebaseio.com",
    projectId: "aapvzw-search-engine",
    storageBucket: "aapvzw-search-engine.appspot.com",
    messagingSenderId: "66343038469",
    appId: "1:66343038469:web:e40182ac7efd9f00be366c",
    measurementId: "G-VE3D5P45MR"
  };

  firebase.initializeApp(firebaseConfig);
  const geo = geofirex.init(firebase);

  $: showOnlyPublic = true;

  let parsed = {};

  if (typeof window !== "undefined") {
    parsed = queryString.parse(window.location.search);
  }

  let isAdmin = parsed.admin === "true";
</script>

<style>
  main {
    text-align: center;
    padding: 0;
    margin: 0 auto;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<main>
  <!-- 1. 🔥 Firebase App -->
  <FirebaseApp {firebase}>
    {#if isAdmin}
      <BitrixView />
    {/if}
  </FirebaseApp>

</main>

<!-- Styles -->
