<script>
  import Avatar from "./Avatar.svelte";
  import { COLORS } from "./../config.js";
  import { getParticipantNameAdmin } from "./../utils/participant.utils.js";
  export let participant;
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  const handleClick = (p) => {
    dispatch("click", p);
  };
</script>

<div class="card" on:click={(e) => handleClick(participant)}>
  <div class="row no-gutters">
    <div class="col">
      <div class="d-flex pl-3">
        <div>
          <Avatar type={participant.type} picture={participant.pictureUrl} />
        </div>
        <div class="card-body text-left">
          <h5 class="card-title">{getParticipantNameAdmin(participant)}</h5>

          <p class="card-text">
            {participant.street || ""}
            {participant.number || ""}
            <br />
            {`${participant.zip || ""} ${participant.city || ""}`}
          </p>

          <div class="status">
            {#if participant.matched}
              <a href="!#">
                <span class="badge badge-primary mr-2">gematched</span>
              </a>
            {/if}
            <a
              href={`https://aapvzw.bitrix24.com/crm/contact/details/${participant.id}/`}
              target="_blank"
            >
              <span class="badge badge-info">bitrix</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  p {
    margin-top: 0;
  }
  .status {
    font-weight: normal;
    margin-bottom: 0.1rem;
  }
  h5 {
    font-weight: bold;
    margin-bottom: 0.1rem;
  }

  .badge {
    font-weight: 600;
    font-size: 10px;
  }

  .badge {
    opacity: 0.75;
  }
  .badge:hover {
    opacity: 1;
  }
  .card {
    height: 120px;
    cursor: pointer;
    border: none;
    border-bottom: 1px solid #d1d1d1;
  }
  .card-text {
    font-size: 12px;
  }
  .card-title {
    font-size: 0.9rem;
  }
  .card:hover {
    background-color: #eee;
  }
  .card:hover .card-title {
    color: #f1592a;
  }
</style>
